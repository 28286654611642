@use "variable" as *;
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a {
    color: $white;
    text-decoration: none;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background: rgba(136, 136, 136, 1);
}

.footer-bottom {
    position:relative;
    .legitscript_link {
        position: absolute;
        left: 10px;
        bottom: 3px;
        width: 70px;
        transition: all 1s ease-in-out;
        &:hover{
            transform: scale(1.4) translate(12px, -17px);
        }
        a{display: inline-block;}
    }
    .ant-menu {
        text-align: center;
    }

    .ant-menu-horizontal {
        line-height: 35px;
    }

    .ant-menu-overflow {
        display: block;
    }

    .ant-menu-dark.ant-menu-horizontal>.ant-menu-item,
    .ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu {
        color: $white;
    }

    .ant-menu-dark.ant-menu-horizontal>.ant-menu-item:hover {
        background-color: transparent;
    }

    .ant-menu-title-content a {
        text-decoration: none;
    }

    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item>a,
    .ant-menu-dark .ant-menu-item>span>a {
        color: $white;
    }

    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
    .ant-menu.ant-menu-dark .ant-menu-item-selected {
        background-color: transparent;
    }

    &.close {
        margin-left: 100px;
        width: calc(100% - 100px);
        @media (min-width : 1025px) {
            margin-left: 100px;
            width: calc(100% - 100px);
        }

        @media (max-width : 1025px) {
            margin-left: 0;
            width: 100%;
        }

    }

    &.open {
        @media (min-width : 1025px) {
            margin-left: 280px;
            width: calc(100% - 280px);
        }
    }
}