.print-card {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 25px;
    font-family: Times New Roman; 
    font-Weight: 400; 
    line-height: 1.538; 
    color: #545454; 
    font-size: 1rem;
    word-wrap: break-word;
    border-radius: 5px;
    background-color: white;
    background-clip: border-box;
    box-shadow: 2px 5px 24px 0 rgba(119, 119, 119, 0.87);
}

@media print {
    .page-break-after {
        page-break-after: always !important;
        &:last-of-type{
            page-break-after: avoid !important;
        }
    }
    .page-break-before {
        page-break-before: always !important;
        &:first-of-type{
            page-break-before: avoid !important;
        }
    }
    .page-break-inside{
        page-break-inside: auto;
    }
    .no-print {
        display: none !important;
    }
    .rpv-core__page-layer::after {
        box-shadow: none
    }
    .print-card {
        position: relative;
        display:block;
        margin-bottom:5px;
        padding:0;
        word-wrap: break-word;
        border-radius:0;
        background-color:transparent;
        background-clip: border-box;
        box-shadow:none;
    }
}