@use "variable" as *;

body {
    &.theme-dark {
        a:hover {
            color: $primary;
        }

        .logo {
            display: none !important;
        }

        .logo-light {
            display: block !important;
        }

        .light-chart {
            display: none;
        }

        label {
            color: $lightgray;
        }

        .menutrigger i {
            color: $lightgray;
        }

        .collapsed-menu {
            .logo-light {
                display: none !important;
            }
        }

        .sidebar-desktop {
            .ant-layout-sider {
                background: $darktheme2;
            }

            .menu-wrap .menu {
                .nav-item>.link {
                    &.active {
                        background-color: #333;
                    }

                    &:hover {
                        background-color: #333;
                    }
                }

                .link {
                    color: $lightgray;
                }
            }

            .dropdown-profile .profile {
                background-color: #58626f;

                &:hover {
                    background-color: #58626f;
                }
            }
        }

        .table {
            &>thead {
                color: $lightgray;
            }

            tbody {
                tr {
                    color: $lightgray;

                    td {
                        background: transparent;
                        color: $lightgray;

                        &:hover {
                            color: $lightgray;
                        }
                    }
                }
            }
        }

        .dropdown-profile {
            .profile {
                background-color: #58626f;
            }

            .dropdown-toggle::after {
                content: none;
            }
        }

        .ant-table-tbody>tr.ant-table-row:hover>td,
        .ant-table-tbody>tr>td.ant-table-cell-row-hover {
            background: $darktheme;
        }

        .orderdetail-orderon {
            background: #ffffff23;
        }

        .dropdownmoveto {
            &.select-orders {
                background: #f3f4f62a;
            }

            .btn-primary {
                color: $white;
            }
        }

        .dropdownaction .btn-primary {
            color: $white;
            background: $darktheme;
            border: 1px solid $darktheme;
        }

        :where(.css-dev-only-do-not-override-1v613y0).ant-table-wrapper .ant,
        .ant {
            &-btn {
                &:hover {
                    background-color: transparent;
                    color: $lightgray;
                }

                &-link {
                    color: $primary;

                    &:hover {
                        background-color: transparent;
                        color: $lightgray;
                    }
                }

                &-default {
                    background-color: $darktheme2;
                    color: $lightgray;
                    border: 1px solid $darktheme2;

                    &:hover {
                        color: $lightgray;
                        background-color: $darktheme2;
                        border: 1px solid $darktheme2;
                    }
                }

                &-lightdark {
                    background-color: $darktheme;
                    color: $lightgray;
                    border: 1px solid $darktheme;

                    &:hover {
                        color: $lightgray;
                        background-color: $darktheme;
                        border: 1px solid $darktheme;
                    }
                }

                &-orderstatus {
                    &:hover {
                        color: $primary;
                    }

                    &:active {
                        color: $primary;
                    }

                    &:focus {
                        color: $primary;
                    }
                }

                &[disabled]:hover &[disabled]:focus,
                &[disabled]:active,
                &[disabled] {
                    color: rgba(247, 247, 247, 0.64);
                    border-color: #222;
                    background: #868686;
                    text-shadow: none;
                    box-shadow: none;
                }
            }

            &-layout {
                background: $darktheme;
            }

            &-result {
                &-icon {
                    color: #dddddd;
                }

                &-extra {
                    color: #dddddd;
                }
            }

            &-table {
                &-column-sorter {
                    color: $lightgray;
                }

                &-column-sorters {
                    &:hover {
                        .ant-table-column-sorter {
                            color: $lightgray;
                        }
                    }
                }

                &-row-expand-icon {
                    background: $black;
                }

                &-expanded-row {
                    .ant-table-cell {
                        background: $darktheme2;
                    }

                    .ant-table-tbody {
                        .ant-table-cell {
                            background: $darktheme
                        }
                    }
                }

                &-tbody {
                    background: $darktheme;

                    &>tr.ant-table-placeholder:hover>td {
                        background: $darktheme;
                    }

                    &>tr>td {
                        border-bottom: 1px solid $Emperor;
                    }
                }

                &-summary {
                    background: $darktheme;
                    color: $lightgray;

                    &>tr.ant-table-placeholder:hover>td {
                        background: $darktheme;
                    }

                    &>tr>td {
                        border-bottom: 1px solid $Emperor;
                    }
                }

                &-thead {
                    &th.ant-table-column-has-sorters:focus-visible {
                        color: $lightgray;
                    }

                    &>tr>th {
                        background: $darktheme2;
                        color: $lightgray;
                        border: none;

                        &:hover {
                            background: $darktheme2;
                        }
                    }
                }

                &-row {
                    color: $lightgray;
                }
            }

            &-table-tbody>tr.ant-table-row:hover>td,
            .ant-table-tbody>tr>td.ant-table-cell-row-hover {
                background-color: $darktheme2;
            }

            &-tabs-content-top {
                border: 0;
            }

            &-tabs-card>.ant-tabs-nav .ant-tabs-tab,
            .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
                background: $darktheme;
                color: $lightgray;
            }

            &-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
            .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
                background: $secondary;
            }

            &-input {
                background: $darktheme;
                color: $dark;
                border: 1px solid $dark !important;

                &[disabled]:hover {
                    color: #b1b1b1;
                }

                &-affix-wrapper {
                    padding: 4px 10px;
                    border: 1px solid rgba(167, 167, 167, 0.651);
                }

                &-wrapper {
                    .ant-input {
                        border: none !important;
                    }
                }

                &-group-addon {
                    background: $darktheme2;
                    color: $lightgray;
                    border: 1px solid $darktheme ;
                }

                &-search {
                    .ant-btn-default {
                        background-color: $darktheme2;
                        color: $lightgray !important;
                        border-left: 0 !important;
                        border: 1px solid rgba(167, 167, 167, 0.651);

                        &:hover {
                            color: $lightgray;
                            background-color: $darktheme2;
                            border: 1px solid rgba(167, 167, 167, 0.651);
                        }
                    }
                }
            }

            &-input::placeholder {
                color: $dark;
            }

            &-input-number {
                background: $darktheme;
                color: $dark;
                border: 1px solid $Emperor;
            }

            &-input-number::placeholder {
                color: $dark;
            }

            &-select:not(.ant-select-customize-input) .ant-select-selector {
                background: $darktheme;
                color: $dark;
                border: 1px solid $Emperor;
            }

            &-upload {
                .ant-btn {
                    background: $darktheme;
                    color: $lightgray;
                }
            }

            &-picker {
                border: 1px solid #b1b1b1 !important;
                background: transparent;

                &-input>input {
                    color: $dark;
                }
            }

            &-modal-content {
                background-color: $darktheme2;
            }

            &-picker-suffix {
                color: $dark;
            }

            &-modal-header {
                background-color: $darktheme2;
                color: $lightgray;
            }

            &-modal-body {
                color: $lightgray;
            }

            &-modal-title {
                color: $lightgray;
            }

            &-modal-footer {
                background: transparent;
            }

            &-modal-confirm-body {
                .ant-modal-confirm-title {
                    color: $lightgray;
                }

                .ant-modal-confirm-content {
                    color: $lightgray;
                }
            }

            &-modal-confirm-btns {
                .ant-btn-default {
                    background-color: $darktheme;
                    color: $lightgray;
                    border: 1px solid $darktheme;

                    &:hover {
                        color: $lightgray;
                        background-color: $darktheme;
                        border: 1px solid $darktheme;
                    }
                }
            }

            &-input-affix-wrapper {
                background-color: $darktheme;
            }

            &-input-password {
                input {
                    border: none;
                }

                .ant-input-password-icon.anticon {
                    color: $lightgray;
                }
            }

            &-input-group {
                input {
                    border: none;
                }

                .ant-input-clear-icon {
                    color: $lightgray;
                }
            }

            &-descriptions-bordered {
                .ant-descriptions-item-label {
                    background: $darktheme2;
                    color: $lightgray;
                }

                .ant-descriptions-item-content {
                    color: $lightgray;
                    background-color: transparent;
                }
            }

            &-collapse {
                background-color: $darktheme;

                &-content {
                    background: $darktheme;
                }

                &>.ant-collapse-item>.ant-collapse-header {
                    color: $lightgray;
                }
            }

            &-divider-horizontal.ant-divider-with-text {
                color: $lightgray;
            }

            &-descriptions-bordered {
                .ant-descriptions-view {
                    border: 1px solid $Emperor;
                }

                .ant-descriptions-row {
                    border-bottom: 1px solid $Emperor;
                }

                .ant-descriptions-item-label {
                    border-right: 1px solid $Emperor;
                }
            }

            &-select-arrow {
                color: $dark;
            }

            &-empty-description {
                color: $lightgray;
            }

            &-checkbox-disabled+span {
                color: $lightgray;
            }

            &-tabs {
                color: $lightgray;
            }

            &-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: $primary;
            }

            &-pagination-next .ant-pagination-item-link {
                background: transparent;
                color: $lightgray;
            }

            &-pagination-prev .ant-pagination-item-link {
                background: transparent;
                color: $lightgray;
            }

            &-pagination-item {
                background: transparent;
                color: $lightgray;

                &-active {
                    border-color: $primary;
                }
            }

            &-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
            .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
                color: $lightgray;
            }

            &-descriptions-bordered {
                .ant-descriptions-item-label {
                    background-color: #1f1f1f;
                }
            }
        }

        td.ant-table-column-sort {
            background: $darktheme
        }

        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: $lightgray;
        }

        .card {
            background: $darktheme2;

            .card-header {
                color: $lightgray;
                background-color: transparent;
            }
        }
        .ant-descriptions-title{color: $lightgray;}
        .text-secondary {
            color: $lightgray !important;
        }

        .theme-breadcrumb {
            a {
                color: $lightgray;
            }

            .breadcrumb-link {
                color: $lightgray;
            }

            ol .breadcrumb-separator {
                color: $lightgray;
            }
        }

        .quill {
            .ql-toolbar.ql-snow {
                background-color: $lightgray;
            }
        }

        //========= Dashboard
        .announcement {
            &-title {
                color: $lightgray;
            }

            &-desc {
                color: rgb(224, 224, 224);
            }
        }

        .select-card {
            &.active {
                background: #206a7a;
            }
        }

        .anticon {
            color: $lightgray;
        }

        .drawer-notification {
            .n-msg {
                color: $lightgray;
            }
        }

        .ant-drawer-content {
            background-color: $darktheme;

            .ant-drawer-title {
                color: $white;
            }
        }

        .ant-badge {
            color: $lightgray;
        }
    }

    &.theme-light {
        .logolight {
            display: none;
        }

        .dark-chart {
            display: none;
        }
    }

    &.theme-Compact {

        :where(.css-dev-only-do-not-override-1v613y0).ant-table-wrapper .ant,
        .ant {
            &-table {
                font-size: 12px;
            }

            &-pagination {
                font-size: 12px;
            }

            &-btn {
                font-size: 12px;
            }

            &-table-tbody>tr>td,
            .ant-table-thead>tr>th,
            .ant-table tfoot>tr>td,
            .ant-table tfoot>tr>th {
                padding: 8px;
            }

            &-input-group .ant-input {
                height: 28px;
            }

            &-input-search-button {
                height: 28px;
            }

            &-descriptions-item-label {
                font-size: 12px;
            }

            &-tabs-tab {
                font-size: 12px;
            }

            &-input-number-input {
                height: 28px;
            }

            &-input {
                padding: 2px 11px;
                font-size: 12px;
            }

            &-select-single:not(.ant-select-customize-input) .ant-select-selector {
                height: 28px;
            }
        }

        .system-section .system-box .nav-link {
            font-size: 14px;
        }

        .system-section .system-box .nav-link .system-icon {
            height: 90px;
            margin-bottom: 5px;
            font-size: 40px;
        }

        header .menutrigger {
            font-size: 30px !important;
        }

        .theme-breadcrumb {
            font-size: 12px;
        }

        .arrowright {
            font-size: 12px;
        }

        .arrowdown {
            font-size: 12px;
        }

        .nav-item {
            font-size: 12px;
        }

        .col {
            padding-right: 0;
        }

        .h3,
        h3 {
            font-size: 1.37rem;
        }

        .h4,
        h4 {
            font-size: 1.25rem;
        }

        .h5,
        h5 {
            font-size: 1.12rem;
        }

        .ms-card {
            margin-bottom: 1rem;
        }

        .card {
            margin-bottom: 15px;

            &-title {
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }

            &-header {
                padding-top: 7px !important;
                padding-bottom: 0px !important;
            }

            &-body {
                padding: 15px;
            }
        }

        label {
            font-size: 12px;
        }

        .custom-sidebar {
            .colortrigger {
                .ant-btn {
                    font-size: 16px;
                }
            }

            h5 {
                font-size: 1.25rem !important;
            }
        }
    }
}