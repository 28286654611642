@use "variable" as *;
.login-section {
    .login-sec-image {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 100vh;
        background: url('../image/login-Rectangle.png') left top/100% 100%;
        background-color: #f8fbf9;

        .logo {
            position: absolute;
            left: 15px;
            top: 15px;
        }

        .login-img2 {
            margin: 0 auto;
        }

        .login-copyright {
            position: absolute;
            bottom: 12px;
            left: 15px;
        }
    }

    .login-section-info {
        display: flex;
        align-items: center;
        min-height: 100vh;
        position: relative;
        background: white;

        .login-form {
            max-width: 320px;
            width: 100%;
            margin: 0 auto;
        }

        .patient-login {
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;
            text-align: center;
        }
    }
    .legitscript_link {
        text-align: center;
        margin-bottom: 14px;
        a{display: inline-block;}
        img{width: 150px;}
    }
}
.login-page {
    min-height: 100vh;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #f6f7fb;

    .card-login {
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
    }
    .legitscript_link {
        text-align: center;
        margin-top:14px;
        margin-bottom:14px;
        a{display: inline-block;}
        img{width: 150px;}
    }
}