@use "variable" as *;
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: $Seashell;
}

::-webkit-scrollbar-thumb {
    background: $gray;
}

::-webkit-scrollbar-thumb:hover {
    background: $Emperor;
}

@media print {
    .orderlabelpage {
        page-break-before: always;
    }
}

.srv-validation-message {
    color: $red;
    font-size: 15px;
}
.srv-validation-message-sm {
    color: $red;
    font-size: 13px;
}

.middle-content-notfound {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 41px);
}

.orderdetail-orderon {
    background: #F9FAFB;
}

.authlayout {
    .right-content {
        min-height: calc(100vh - 48px);
        padding: 15px;
    }
}

a:hover {
    color: $primary;
}

.order-from {
    background-color: #e3fcff;
    color: #0097aa;
}

.right-content {
    &.close {
        @media (min-width : 1025px) {
            margin-left: 100px;
        }

        @media (max-width : 1025px) {
            margin-top: 45px;
        }
    }

    &.open {
        @media (min-width : 1025px) {
            margin-left: 280px;
        }
    }
}

.ant-table-filter-trigger {
    color: $white;
}

.ant-table-filter-trigger:hover {
    color: $black;
    background-color: $white;
    border-radius: 50px;
}

.ant-table-filter-trigger.active {
    background-color: $white;
    border-radius: 50px;
}

.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
    color: $black;
}

.btn-orderstatus {
    border-bottom: 2px solid #6366F1;

    &:active,
    &:hover,
    &:focus {
        border-bottom: 2px solid #6366F1;
        color: $black;
    }
}

.btn-ordertype {
    border-bottom: 2px solid #6366F1;

    &:active,
    &:hover,
    &:focus {
        border-bottom: 2px solid #6366F1;
        color: $black;
    }
}

.orders-list {
    .order-title {
        font-size: 16px;
        font-weight: 500;
        word-break: break-all;
    }

    .order-id {
        padding-bottom: 5px;
    }

    .order-date {
        padding-bottom: 5px;
        white-space: nowrap;
    }

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px lightgray;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $primary;
    }
}

.ant-checkbox-checked:after {
    border: none;
}

.dropdownmoveto {
    &.select-orders {
        background: #F3F4F6;
    }

    .btn-primary {
        background: transparent;
        color: $black;
        border: none;
        padding-top: 2px;
        padding-bottom: 0px;
        padding-right: 7px;
        padding-left: 7px;

        &::after {
            margin-left: 0.400em;
            vertical-align: 0.200em;
        }

        &:active {
            background-color: transparent;
            color: $black;
            border: none;
        }
    }
}

.dropdownaction {
    .btn-primary {
        background: transparent;
        color: $black;
        border: 1px solid $black;

        &::after {
            margin-left: 0.400em;
            vertical-align: 0.200em;
        }

        &:active {
            background-color: transparent;
            color: $black;
            border: 1px solid $black;
        }
    }
}

//======== System Page Grid
.system-section {
    a {
        text-decoration: none;
    }
    .card .card-body {
        padding: 15px;
    }
    .system-box {
        .nav-link {
            font-size: 16px;
            color: $primary;
            display: block;
            transition: all 0.3s ease-in-out;

            .system-icon {
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                border-radius: 50%;
                margin-bottom: 15px;
                font-size: 50px;
                color: $primary;
            }

            .fa-duotone {
                &::before {
                    color: $primary;
                }

                &::after {
                    color: $secondary;
                }
            }

            &:hover {
                color: $secondary;
                transform: scale(1.05);
            }
        }
    }
    .system-icon {
        font-size: 16px;
    }

    .system-title {
        font-size: 16px;
        font-weight: 500;
        padding-top: 10px;
        padding-bottom:5px;
    }

    .card-product .card {
        background-color: $product;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }

    .card-announcement .card {
        background-color: $announcement;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }

    .card-users .card {
        background-color: $users;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }

    .card-template .card {
        background-color: $template;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }

    .card-qaqc .card {
        background-color: $qaqc;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }

    .card-other .card {
        background-color: $other;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }
}

//======== Other Page Grid
.other-section {
    a {
        text-decoration: none;
    }

    .other-icon {
        font-size: 16px;
    }

    .other-title {
        font-size: 16px;
        font-weight: 500;
        padding-top: 15px;
        padding-bottom: 5px;
    }

    .card-product .card {
        background-color: $product;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }

    .card-announcement .card {
        background-color: $announcement;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }

    .card-users .card {
        background-color: $users;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }
}

//======== Report Page Grid
.report-section {
    a {
        text-decoration: none;
    }

    .report-icon {
        font-size: 16px;
    }

    .report-title {
        font-size: 16px;
        font-weight: 500;
        padding-top: 15px;
        padding-bottom: 5px;
    }

    .card-allreport .card {
        background-color: $product;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }

    .card-otherreport .card {
        background-color: $announcement;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }

    .card-rxonereport .card {
        background-color: $users;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }

    .card-nilereport .card {
        background-color: $template;
        text-align: center;
        color: $black;
        margin-bottom: 0;
        box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0);
    }
}

table {
    &.thm-default {
        font-size: 14px;

        > :not(:first-child) {
            border-top: 2px solid rgba($secondary, 0.4);
        }

        >thead {
            background-color: rgba($secondary, 0.2);
        }

        tbody {
            tr {
                &.tr-successLight {
                    background-color: rgba($success, 0.15);
                }

                &.tr-dangerLight {
                    background-color: rgba($danger, 0.15);
                }

                tr.rowclick {
                    cursor: pointer;
                }
            }
        }
    }

    &.thead-bg-secondary {
        thead>tr>th {
            color: $white;
            background-color: $green;
        }
    }

    .bg-color {
        &-danger {
            background-color: $danger;
            color: $white;
        }

        &-success {
            background-color: $success;
        }

        &-yellow {
            background-color: $yellow;
        }

        &-orange {
            background-color: $orange;
        }
    }
}

.select-card {
    cursor: pointer;
    border-left: 5px solid transparent;
    box-shadow: none;
    border-bottom: thin solid #dedede;
    border-radius: 0;

    .patientcard-radio {
        display: none;
        width: 22px;
        line-height: 1;
    }

    .ordercard-radio {
        display: none;
    }

    &:hover {
        .ordercard-radio {
            display: block;
        }

        .patientcard-radio {
            display: block;
        }

        .ordercard-avtar {
            display: none;
        }

        .patientcard-avtar {
            display: none;
        }
    }

    &.active {
        background: rgba(233, 251, 255, 1);
        border-left: 5px solid $primary;
        transition: 0s;
        cursor: auto;
    }

    .ant-checkbox-wrapper {
        line-height: 1;

        .ant-checkbox {
            position: relative;

            .ant-checkbox-inner {
                border-radius: 10px;
                width: 20px;
                height: 20px;
            }
        }
    }

    &.sidebar-item-order {
        .avtar-wrap {
            padding: 5px 15px 7px 0;
        }

        .order-price {
            text-align: end;

            @media only screen and (max-width: 1225px) and (min-width: 768px) {
                text-align: start;
            }
        }

        .row>div {
            padding: 0;
        }

        .r1 {
            justify-content: space-between;
            flex-wrap: nowrap;

            @media only screen and (max-width: 1399px) and (min-width: 768px) {
                flex-direction: column;
            }
        }

        .r2 {
            justify-content: space-between;
            flex-wrap: nowrap;

            @media only screen and (max-width: 1225px) and (min-width: 768px) {
                flex-direction: column;
            }
        }

        .r3 {
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        .order-store {
            word-break: break-all;
        }
    }
}

.select-all-checkbox {
    .ant-checkbox-wrapper {
        .ant-checkbox {
            top: 5px;

            .ant-checkbox-inner {
                border-radius: 10px;
                width: 20px;
                height: 20px;
            }
        }
    }
}

/*==============
ant design controll
=================*/
.AntInputEment {
    width: 100%;
}

.AntInputEmentHover {
    border-color: $primary;
    box-shadow: none;
}

.AntEditable {
    height: calc(1.2rem + 0.5rem + 2px);
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
    line-height: 1;
    border-radius: 0;
    border: 1px solid $Alabaster;
    background-color: $Alabaster;
}

.Antbtnlg {
    height: auto;
    padding: 10px 19px;
    font-size: 16px;
    font-weight: 600;
}

.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: 0;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:where(.css-dev-only-do-not-override-1v613y0).ant-table-wrapper .ant,
.ant {
    &-layout {
        font-family: "Roboto", sans-serif;
        background: $layoutbg;
    }
    &-table {
        font-family: "Roboto", sans-serif;
        &-thead {
            &>tr {
                &>th {
                    background: #F3F4F6;
                    color: $black;
                    padding: 8px;
                    padding-left: 14px;
                }
            }
        }

        &-tbody {
            &>tr {
                &>td {
                    padding: 8px;
                    padding-left: 14px;
                }
            }
        }

        &-column {
            &-sorter {
                color: $black;
            }

            &-sorters {
                &:hover {
                    .ant-table-column-sorter {
                        color: $black;
                    }
                }
            }
        }
        &-summary {
            background: #f7f7f7;
        }
    }

    &-input {
        border-radius: 5px;
        @extend .AntInputEment;

        &::placeholder {
            color: rgba($black, 0.4);
        }

        &.ant-editable {
            @extend .AntEditable;
        }

        &.round {
            border-radius: 8px;
        }

        &[disabled] {
            color: $black;

            &:hover {
                color: $black;
            }
        }

        &-sm {
            height: calc(0.85em + 0.85rem + 2px);
            padding: 0.25rem 0.8rem;
            font-size: 0.8rem;
            line-height: 0.8;
            border-radius: 0;
        }

        &:focus,
        &-focused {
            @extend .AntInputEmentHover;
        }

        &-textarea-show-count::after {
            margin: 4px 0 7px;
            font-size: 85%;
        }

        &-affix-wrapper {
            @extend .AntInputEment;
            border: none;
            border-radius: 5px;
            padding: 4px 10px;
            border: 1px solid rgba(0, 0, 0, .20);

            &-lg {
                padding: 6.5px 11px;
            }

            >input.ant-input {
                padding: 0;
                border: none;
                outline: none;
                height: auto;
            }

            &:not(.ant-input-affix-wrapper-disabled):hover,
            &:not(.ant-input-affix-wrapper-disabled):focus {
                @extend .AntInputEmentHover;
            }
        }

        &-number {
            @extend .AntInputEment;

            &.round {
                border-radius: 8px;
            }

            >input.ant-input {
                padding: 0;
                border: none;
                outline: none;
                height: auto;
            }

            &-handler-wrap {
                right: 9px;
            }

            &.ant-editable {
                @extend .AntEditable;

                .ant-input-number-input {
                    height: auto;
                }
            }

            &-focused {
                @extend .AntInputEmentHover;
            }

            &-group-wrapper {
                &.round {
                    .ant-input-number-group-addon {
                        border-radius: 8px;

                        &:last-child {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }

                        &:first-child {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                }
            }
        }

        &-group-addon:first-child {
            border-right: 1px solid #d9d9d9;
        }

        &-search {
            .ant-input {
                &-affix-wrapper {
                    border: 0;
                    padding: 5px 10px;
                    border-radius: 8px 0 0 8px !important;
                }
            }

            .ant-btn-default {
                border: 0;
                border-radius: 0 8px 8px 0 !important;
            }
            .ant-input-wrapper {
                border-radius:2px;
               box-shadow: 1px 2px 10px 0 rgba(17, 17, 19, 0.44);
            }
        }
    }

    &-select {
        width: 100%;

        &.round {
            &:not(.ant-select-customize-input) .ant-select-selector {
                border-radius: 8px;
            }
        }

        &.ant-editable {
            .ant-select-selector {
                border-radius: 5px;
                @extend .AntEditable;

                .ant-select-selection {

                    &-item,
                    &-placeholder {
                        line-height: 1.4rem;
                    }
                }
            }
        }

        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                border-radius: 5px;
                @extend .AntInputEment;

                .ant-select-selection-search-input {
                    height: 100%;
                }
            }
        }

        &-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
            .ant-select-selector {
                @extend .AntInputEmentHover;
            }
        }
    }

    &-picker {
        @extend .AntInputEment;
        border-radius: 5px;

        &.round {
            border-radius: 8px;
        }

        &.ant-editable {
            @extend .AntEditable;
        }

        &:focus,
        &-focused {
            @extend .AntInputEmentHover;
        }
    }

    //-------------ant modal
    &-modal {
        top: 0;
        padding-bottom: 24px;
        padding-top: 24px;

        &-confirm {
            .ant-modal-body {
                padding: 24px 24px 24px;
            }
        }

        &-close-x {
            width: 35px;
            height: 35px;
            font-size: 15px;
            line-height: 33px;
            color: $white;
            background-color: $secondary;

            &:hover {
                background-color: $secondaryhover;
            }
        }

        &-title {
            font-size: 23px;
            font-weight: 600;
        }

        &-content {
            border-radius: 6px;
        }

        &-header {
            padding: 34px 24px 0;
            border-radius: 6px 6px 0 0;
        }

        &-body {
            max-height: calc(100vh - 126px);
            overflow-y: auto;
        }

        &-footer {
            border-radius: 0 0 2px 2px;
            background: $footer;
        }
    }

    //------------ant avatar
    &-avatar {
        &-group {
            vertical-align: top;
        }

        &-add {
            color: rgb(245, 106, 0);
            background-color: rgb(253, 227, 207);
        }

        &.bg-default {
            background-color: $primary;
        }
    }

    &-progress {
        line-height: 9px;
        vertical-align: top;

        &-inner {
            vertical-align: top;
            border-radius: 0;
        }
    }

    //------------ant list
    &-list {
        &-item {
            &.hovered {
                &:hover {
                    background-color: $WildSand;
                }
            }
        }

        &.list-nodata-none {
            .ant-list-empty-text {
                display: none;
            }
        }
    }

    //-----------ant button
    &-btn {
        text-decoration: none;
        border-radius: 4px;
        font-weight: 500;

        &:hover {
            color: $black;
            background-color: $white
        }

        &-lg {
            @extend .Antbtnlg;
        }

        &-round {
            &.ant-btn-lg {
                @extend .Antbtnlg;
            }
        }

        &-link {
            color: $black;
        }
   
        &-primary {
            color: $white;
            background: $primary;
            border-color: $primary;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                background: $primary;
                border-color: $primaryhover;
            }
        }

        &-secondary {
            color: $white;
            background: linear-gradient(to bottom, rgba($secondary, 0.8), $secondary, rgba($secondary, 0.8));
            border-color: $secondary;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                background: linear-gradient(to bottom, rgba($secondaryhover, 0.8), $secondaryhover, rgba($secondaryhover, 0.8));
                border-color: $secondaryhover;
            }
        }

        &-lightblack {
            color: $white;
            background: linear-gradient(to bottom, rgba($lightblack, 0.8), $lightblack, rgba($lightblack, 0.8));
            border-color: $lightblack;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                background: linear-gradient(to bottom, rgba($black, 0.8), $black, rgba($black, 0.8));
                border-color: $black;
            }
        }

        &-success {
            color: $white;
            background: linear-gradient(to bottom, rgba($success, 0.8), $success, rgba($success, 0.8));
            border-color: $success;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                background: linear-gradient(to bottom, rgba($success, 0.8), $success, rgba($success, 0.8));
                border-color: $successhover;
            }
        }

        &-danger {
            color: $white;
            background: linear-gradient(to bottom, rgba($danger, 0.8), $danger, rgba($danger, 0.8));
            border-color: $danger;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                background: linear-gradient(to bottom, rgba($dangerhover, 0.8), $dangerhover, rgba($dangerhover, 0.8));
                border-color: $dangerhover;
            }
        }

        &-warning {
            color: $white;
            background: linear-gradient(to bottom, rgba($warning, 0.8), $warning, rgba($warning, 0.8));
            border-color: $warning;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                background: linear-gradient(to bottom, rgba($warninghover, 0.8), $warninghover, rgba($warninghover, 0.8));
                border-color: $warninghover;
            }
        }

        &-info {
            color: $white;
            background: linear-gradient(to bottom, rgba($info, 0.8), $info, rgba($info, 0.8));
            border-color: $info;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                background: linear-gradient(to bottom, rgba($infohover, 0.8), $infohover, rgba($infohover, 0.8));
                border-color: $infohover;
            }
        }

        &-light {
            color: $MineShaft;
            background: linear-gradient(to bottom, rgba($light, 0.8), $light, rgba($light, 0.8));
            border-color: $light;

            &:active,
            &:hover,
            &:focus {
                color: $MineShaft;
                background: linear-gradient(to bottom, rgba($lighthover, 0.8), $lighthover, rgba($lighthover, 0.8));
                border-color: $lighthover;
            }
        }

        &-dark {
            color: $black;
            background: linear-gradient(to bottom, rgba($dark, 0.8), $dark, rgba($dark, 0.8));
            border-color: $dark;

            &:active,
            &:hover,
            &:focus {
                color: $black;
                background: linear-gradient(to bottom, rgba($darkhover, 0.8), $darkhover, rgba($darkhover, 0.8));
                border-color: $darkhover;
            }
        }

        &-ghost {
            color: $primary;
            border-color: $primary;
            background: transparent;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                border-color: $primary;
                background: $primaryhover;
            }

            &.secondary {
                color: $secondary;
                background: transparent;
                border-color: $secondary;

                &:active,
                &:hover,
                &:focus {
                    color: $white;
                    background: linear-gradient(to bottom, rgba($secondaryhover, 0.8), $secondaryhover, rgba($secondaryhover, 0.8));
                    border-color: $secondaryhover;
                }
            }

            &.success {
                color: $success;
                background: transparent;
                border-color: $success;

                &:active,
                &:hover,
                &:focus {
                    color: $white;
                    background: linear-gradient(to bottom, rgba($successhover, 0.8), $successhover, rgba($successhover, 0.8));
                    border-color: $successhover;
                }
            }

            &.danger {
                color: $danger;
                background: transparent;
                border-color: $danger;

                &:active,
                &:hover,
                &:focus {
                    color: $white;
                    background: linear-gradient(to bottom, rgba($dangerhover, 0.8), $dangerhover, rgba($dangerhover, 0.8));
                    border-color: $dangerhover;
                }
            }

            &.warning {
                color: $warning;
                background: transparent;
                border-color: $warning;

                &:active,
                &:hover,
                &:focus {
                    color: $white;
                    background: linear-gradient(to bottom, rgba($warninghover, 0.8), $warninghover, rgba($warninghover, 0.8));
                    border-color: $warninghover;
                }
            }

            &.info {
                color: $info;
                background: transparent;
                border-color: $info;

                &:active,
                &:hover,
                &:focus {
                    color: $white;
                    background: linear-gradient(to bottom, rgba($infohover, 0.8), $infohover, rgba($infohover, 0.8));
                    border-color: $infohover;
                }
            }

            &.dark {
                color: $black;
                background: transparent;
                border-color: $dark;

                &:active,
                &:hover,
                &:focus {
                    color: $white;
                    background: linear-gradient(to bottom, rgba($darkhover, 0.8), $darkhover, rgba($darkhover, 0.8));
                    border-color: $darkhover;
                }
            }
        }

        &-status-primary {
            color: $white;
            background: $primary;
            border-color: $primary;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                background: linear-gradient(to bottom, rgba($primaryhover, 0.8), $primaryhover, rgba($primaryhover, 0.8));
                border-color: $primaryhover;
            }
        }

        &-status-link {
            color: #ff4d4f;
            border-color: transparent;
            background: transparent;
            box-shadow: none;

            &:active,
            &:hover,
            &:focus {
                color: #ff7875;
                border-color: transparent;
                background: transparent;
            }
        }

        &-status-flat {
            color: $blue;
            border-color: transparent;
            background: transparent;
            box-shadow: none;

            &:active,
            &:hover,
            &:focus {
                color: $blue;
                border-color: transparent;
                background: transparent;
            }
        }

        &-status-danger {
            color: $white;
            background: linear-gradient(to bottom, rgba($danger, 0.8), $danger, rgba($danger, 0.8));
            border-color: $danger;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                background: linear-gradient(to bottom, rgba($dangerhover, 0.8), $dangerhover, rgba($dangerhover, 0.8));
                border-color: $dangerhover;
            }
        }

        &-status-danger {
            color: $white;
            background: linear-gradient(to bottom, rgba($danger, 0.8), $danger, rgba($danger, 0.8));
            border-color: $danger;

            &:active,
            &:hover,
            &:focus {
                color: $white;
                background: linear-gradient(to bottom, rgba($dangerhover, 0.8), $dangerhover, rgba($dangerhover, 0.8));
                border-color: $dangerhover;
            }
        }

        &-status-pending {
            color: $darkpending;
            background: $pending;
            border-color: $darkpending;

            &:active,
            &:hover,
            &:focus {
                color: $darkpending;
                background: $pending;
                border-color: $darkpending;
            }
        }

        &-status-followup {
            color: $darkfollowup;
            background: $followup;
            border-color: $darkfollowup;

            &:active,
            &:hover,
            &:focus {
                color: $darkfollowup;
                background: $followup;
                border-color: $darkfollowup;
            }
        }

        &-status-confirmed {
            color: $darkconfirmed;
            background: $confirmed;
            border-color: $darkconfirmed;

            &:active,
            &:hover,
            &:focus {
                color: $darkconfirmed;
                background: $confirmed;
                border-color: $darkconfirmed;
            }
        }

        &-status-fastmoving {
            color: $darkfastmoving;
            background: $fastmoving;
            border-color: $darkfastmoving;

            &:active,
            &:hover,
            &:focus {
                color: $darkfastmoving;
                background: $fastmoving;
                border-color: $darkfastmoving;
            }
        }

        &-status-compounding {
            color: $darkcompounding;
            background: $compounding;
            border-color: $darkcompounding;

            &:active,
            &:hover,
            &:focus {
                color: $darkcompounding;
                background: $compounding;
                border-color: $darkcompounding;
            }
        }

        &-status-finalcheck {
            color: $darkfinalcheck;
            background: $finalcheck;
            border-color: $darkfinalcheck;

            &:active,
            &:hover,
            &:focus {
                color: $darkfinalcheck;
                background: $finalcheck;
                border-color: $darkfinalcheck;
            }
        }

        &-status-readyforcollection {
            color: $darkreadyforcollection;
            background: $readyforcollection;
            border-color: $darkreadyforcollection;

            &:active,
            &:hover,
            &:focus {
                color: $darkreadyforcollection;
                background: $readyforcollection;
                border-color: $darkreadyforcollection;
            }
        }

        &-status-posted {
            color: $darkposted;
            background: $posted;
            border-color: $darkposted;

            &:active,
            &:hover,
            &:focus {
                color: $darkposted;
                background: $posted;
                border-color: $darkposted;
            }
        }

        &-status-cancelled {
            color: $darkcancelled;
            background: $cancelled;
            border-color: $darkcancelled;

            &:active,
            &:hover,
            &:focus {
                color: $darkcancelled;
                background: $cancelled;
                border-color: $darkcancelled;
            }
        }

        &-status-rejected {
            color: $darkrejected;
            background: $rejected;
            border-color: $darkrejected;

            &:active,
            &:hover,
            &:focus {
                color: $darkrejected;
                background: $rejected;
                border-color: $darkrejected;
            }
        }
        &[disabled]:hover
        &[disabled]:focus,
        &[disabled]:active,
        &[disabled]{
            color: rgba(0,0,0,.25);
            border-color: #d9d9d9;
            background: #f5f5f5;
            text-shadow: none;
            box-shadow: none;
        }
    }

    &-switch {
        &-checked {
            background: $primary;
        }
    }

    //----------ant checkbox
    &-checkbox-checked {
        .ant-checkbox-inner {
            background-color: $primary;
            border-color: $primary;
        }
    }

    &-radio-checked {
        .ant-radio-inner {
            border-color: $primary;

            &::after {
                background-color: $primary;
            }
        }
    }

    //----------ant tab
    &-tabs {
        &-tab {
            margin: 0 16px 0 16px;

            &-btn:hover,
            &-btn:focus {
                color: $primary;
                text-shadow: none;
            }

            &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: $black;
                    text-shadow: none;
                }
            }
        }

        &-ink-bar {
            background: $primary;
        }
    }

    &-card {
        &-small {
            >.ant-card-body {
                padding: 8px 11px;
            }
        }
    }

    &-steps {
        overflow-x: auto;

        &-dot {
            .ant-steps-item-content {
                width: 130px;
            }

            .ant-steps-item-icon {
                margin-left: 60px;
            }

            .ant-steps-item-process {
                .ant-steps-item-icon {
                    top: 0;
                    width: 8px;
                    height: 8px;
                    line-height: 8px;
                }
            }

            &.ant-steps-small {
                .ant-steps-item-content {
                    width: 130px;
                }

                .ant-steps-item-icon {
                    margin-left: 60px;
                }

                .ant-steps-item-process {
                    .ant-steps-item-icon {
                        top: 0;
                        width: 8px;
                        height: 8px;
                        line-height: 8px;
                    }
                }
            }
        }
    }

    &-collapse {
        &-icon-position-right> {
            .ant-collapse-item {
                >.ant-collapse-header {
                    padding: 17px 45px 17px 25px;
                }
            }
        }

        >.ant-collapse-item {
            border-bottom: 1px solid $Alto;

            >.ant-collapse-header {
                align-items: center;
            }

            &:last-child {
                >.ant-collapse-header {
                    border-radius: 0 0 18px 18px;
                }

                >.ant-collapse-content {
                    border-radius: 0 0 18px 18px;
                }
            }
        }

        &-header-text {
            flex: auto;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
        }
    }

    &-breadcrumb {
        i {
            color: $secondary;
        }

        a {
            color: $primary;
            text-decoration: underline;

            &:hover {
                color: $primary;
            }
        }
    }

    &-result {
        opacity: 0.3;
        padding: 25% 32px;

        &-icon {
            font-size: 40px;
        }

        &-extra {
            color: #787878;
            font-size: 22px;
            font-weight: 500;
        }
    }

    &-divider {
        border-top: 1px solid rgba(0, 0, 0, 0.20);

        &-horizontal {
            margin: 15px 0;
        }
    }
    &-descriptions-bordered{
        .ant-descriptions-item-label {
            background-color: #f0f0f0;
        }
    } 
}

textarea {
    &.ant-input {
        &.ant-editable {
            height: auto;
            line-height: 1.8;
        }
    }
}

/******** quill text editor ***********/
.quill {
    .ql-toolbar.ql-snow {
        border-radius: 5px 5px 0 0;
    }

    .ql-container.ql-snow {
        border-radius: 0 0 5px 5px;
    }

    .ql {
        &-editor {
            height: 250px;
        }
    }
}

/*=========
 P A G E  
===========*/

.optout-section {
    .optout-section-info {
        display: flex;
        align-items: center;
        min-height: 100vh;
        position: relative;
        background: $primary;

        @media (min-width : 768px) {
            background: $layoutbg;
        }

        .optout-form {
            max-width: 800px;
            width: 100%;
            margin: 0 auto;

            .company-info {
                display: block;
                color: $white;
                background: $primary;
                padding: 130px 30px 40px;
                text-align: end;

                @media (max-width: 767.98px) {
                    display: none;
                }

                label {
                    color: $white;
                    font-size: 16px;
                    margin-top: 15px;
                    margin-bottom: 0.5rem;
                    font-weight: 600;
                }
            }

            .company-form {
                background: $white;
                padding: 60px 30px 40px;
                text-align: center;
            }
        }
    }
}

.tokenresult-section {
    display: flex;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    position: relative;
    background: url('../image/login-Rectangle.png') left top/100% 100%;
    background-color: #f8fbf9;

    .tokenresult-warning {
        margin: 0 auto;
    }
}

//=========Script Print Page
.print-main-content {
    color: #545454;
    font-size: 13px;
    line-height: 1.538;
    padding: 10px 50px;

    .container {
        max-width: 1440px;
    }
}

/*****/