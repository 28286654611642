@use "variable" as *;
body {
    font-size: 14px;
    line-height: 18px;
    color: $gray;
    font-family: 'Roboto', sans-serif;
    background-color: $Alabaster;
}

a {
    color: inherit;
}

.underlinenone:hover,
.underlinenone {
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.clearfix {
    clear: both;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: $MineShaft;
}

.border-dashed {
    border: thin dashed;
}

.overflowY-auto {
    overflow-y: auto;
}

*:focus-visible {
    outline: none;
}

/**** text and bg color  *****/
.color {
    &-primary {
        color: $primary;
    }

    &-secondary {
        color: $secondary;
    }

    &-success {
        color: $success;
    }

    &-danger {
        color: $danger;
    }

    &-warning {
        color: $warning;
    }

    &-info {
        color: $info;
    }

    &-light {
        color: $light;
    }

    &-dark {
        color: $dark;
    }

    &-mineshaft {
        color: $MineShaft;
    }

    &-white {
        color: $white;
    }

    &-black {
        color: $black;
    }

    &-green {
        color: $green;
    }

    &-blue {
        color: $blue;
    }

    &-darktheme {
        color: $darktheme;
    }

    &-darkwarning {
        color: $darkwarning;
    }

    &-pending {
        color: $darkpending;
    }

    &-cancelled {
        color: $darkcancelled;
    }

    &-archived {
        color: $darkarchived;
    }

    &-finalcheck {
        color: $darkfinalcheck;
    }

    &-followup {
        color: $darkfollowup;
    }

    &-confirmed {
        color: $darkconfirmed;
    }

    &-fastmoving {
        color: $darkfastmoving;
    }

    &-compounding {
        color: $darkcompounding;
    }

    &-onshelf {
        color: $darkreadyforcollection;
    }

    &-posted {
        color: $darkposted;
    }

    &-rejected {
        color: $darkrejected;
    }

    &-enabled {
        color: $darkfinalcheck;
    }

    &-disabled {
        color: $darkrejected;
    }

    &-open {
        color: $darkfinalcheck;
    }

    &-closed {
        color: $darkrejected;
    }

    &-approved {
        color: $darkfinalcheck;
    }

    &-declined {
        color: $darkrejected;
    }

    &-on-hold {
        color: $darkpending;
    }

    &-user {
        color: $user;
    }

    &-delay {
        color: $white;
    }

    &-customer-replied {
        color: $darkfollowup;
    }

    &-staff-replied {
        color: $darkreadyforcollection;
    }
    &-collected  {
        color: #155724;
    }
}

.bg-color {
    &-transparent {
        background-color: transparent;
    }

    &-primary {
        background-color: $primary;
    }

    &-secondary {
        background-color: $secondary;
    }

    &-success {
        background-color: $success;
    }

    &-danger {
        background-color: $danger;
    }

    &-warning {
        background-color: $warning;
    }

    &-info {
        background-color: $info;
    }

    &-light {
        background-color: $light;
    }

    &-dark {
        background-color: $dark;
    }

    &-black {
        background-color: $black;
    }

    &-white {
        background-color: $white;
    }

    &-lightblack {
        background-color: $lightblack;
    }

    &-red {
        background-color: $red;
    }

    &-green {
        background-color: $green;
    }

    &-yellow {
        background-color: $yellow;
    }

    &-lightwarning {
        background-color: $lightwarning;
    }

    &-store {
        background-color: $store;
    }

    &-user {
        background-color: $user;
    }

    &-product {
        background-color: $product;
    }

    &-announcement {
        background-color: $announcement;
    }

    &-users {
        background-color: $users;
    }

    &-template {
        background-color: $template;
    }

    &-qaqc {
        background-color: $qaqc;
    }

    &-other {
        background-color: $other;
    }

    &-status {
        &-cancelled {
            background-color: $cancelled;
        }

        &-archived {
            background-color: $archived;
        }

        &-pending {
            background-color: $pending;
        }

        &-finalcheck {
            background-color: $finalcheck;
        }

        &-followup {
            background-color: $followup;
        }

        &-confirmed {
            background-color: $confirmed;
        }

        &-fastmoving {
            background-color: $fastmoving;
        }

        &-compounding {
            background-color: $compounding;
        }

        &-onshelf {
            background-color: $readyforcollection;
        }

        &-onShelf {
            background-color: $readyforcollection;
        }

        &-posted {
            background-color: $posted;
        }

        &-rejected {
            background-color: $rejected;
        }

        &-enabled {
            background-color: $finalcheck;
        }

        &-disabled {
            background-color: $rejected;
        }

        &-open {
            background-color: $finalcheck;
        }

        &-closed {
            background-color: $rejected;
        }

        &-approved {
            background-color: $finalcheck;
        }

        &-declined {
            background-color: $rejected;
        }

        &-on-hold {
            background-color: $pending;
        }

        &-customer-replied {
            background-color: $followup;
        }

        &-staff-replied {
            background-color: $readyforcollection;
        }

        &-scheduled {
            background-color: #12ff5d;
        }

        &-delay {
            background-color: #ff4343;
        }
        &-collected {
            background-color: #d4edda;

        }
    }
}

.fw {
    &-300 {
        font-weight: 300;
    }

    &-400 {
        font-weight: 400;
    }

    &-500 {
        font-weight: 500;
    }

    &-600 {
        font-weight: 600;
    }

    &-700 {
        font-weight: 700;
    }

    &-800 {
        font-weight: 800;
    }

    &-900 {
        font-weight: 900;
    }
}

.fs {
    &-12 {
        font-size: 12px;
    }

    &-13 {
        font-size: 13px;
    }

    &-14 {
        font-size: 14px;
    }

    &-15 {
        font-size: 15px;
    }

    &-16 {
        font-size: 16px;
    }

    &-17 {
        font-size: 17px;
    }

    &-18 {
        font-size: 18px;
    }

    &-20 {
        font-size: 20px;
    }

    &-22 {
        font-size: 22px;
    }

    &-24 {
        font-size: 24px;
    }

    &-26 {
        font-size: 26px;
    }

    &-28 {
        font-size: 28px;
    }

    &-30 {
        font-size: 30px;
    }

    &-32 {
        font-size: 32px;
    }

    &-34 {
        font-size: 34px;
        line-height: 34px;
    }

    &-36 {
        font-size: 36px;
        line-height: 36px;
    }

    &-38 {
        font-size: 38px;
        line-height: 38px;
    }

    &-40 {
        font-size: 40px;
        line-height: 40px;
    }

    &-70 {
        font-size: 70px;
        line-height: 70px;
    }

    &-80 {
        font-size: 80px;
        line-height: 80px;
    }
}

.mw {
    &-410 {
        max-width: 410px;
    }

    &-460 {
        max-width: 460px;
    }

    &-522 {
        max-width: 522px;
    }

    &-680 {
        max-width: 680px;
    }

    &-710 {
        max-width: 710px;
    }

    &-800 {
        max-width: 800px;
    }

    &-990 {
        max-width: 990px;
    }
}

//====== Border Radius
.br {
    &-20 {
        border-radius: 20px;
    }
}

.h {
    &-full {
        height: 100%;
    }
}

p {
    margin-bottom: 10px;
}

/*** figure *****/
img {
    max-width: 100%;

    &.rounded-circle {
        padding: 2px;
        box-shadow: 2px 3px 11px 0px rgba($primary, 0.34);
    }
}

.figure-circle {
    border-radius: 100%;
    object-fit: cover;
}

.block {
    width: 100%;
    padding: 5px 19px;
}

/**** buttons ***/
.btn {
    padding: .2rem 0.9rem;
    font-size: 0.9rem;
    border-radius: 0;

    &.btn-div {
        cursor: default !important;
    }

    &:active:focus,
    &:active,
    &:focus,
    &:hover {
        box-shadow: none;
        outline: none;
    }

    &.btn-primary1 {
        color: $white;
        background-color: $primary;
        border-color: $primary;

        &.active,
        &:hover {
            color: $white;
            background-color: $primaryhover;
            border-color: $primaryhover;
        }
    }

    &.btn-outline-primary1 {
        color: $primary;
        border-color: $primary;

        &.active,
        &:hover {
            color: $white;
            background-color: $primary;
            border-color: $primary;
        }
    }

    &.btn-secondary1 {
        color: $white;
        background-color: $secondary;
        border-color: $secondary;

        &.active,
        &:hover {
            color: $white;
            background-color: $secondaryhover;
            border-color: $secondaryhover;
        }
    }

    &.btn-outline-secondary1 {
        color: $secondary;
        border-color: $secondary;

        &.active,
        &:hover {
            color: $white;
            background-color: $secondary;
            border-color: $secondary;
        }
    }
}

/**** form controls ***/
.form-label {
    font-size: 14px;
    font-weight: 400;
    color: rgba($black, 0.7);
}
.form-label-small{
    font-size: 13px;
    font-weight:400;
    color:rgba($black, 0.7);
    margin-bottom:2px;
}

.form-control {
    height: calc(1.17rem + .5rem + 2px);
    color: $Emperor;
    border-radius: 0.1rem;
    border: 1px solid $AthensGray;
    background: $white;

    &-hide {
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
    }

    &:focus {
        border-color: $primary;
        outline: 0;
        box-shadow: none;

        &::placeholder {
            color: transparent;
        }

        &::-webkit-input-placeholder {
            color: transparent;
        }

        &::-moz-placeholder {
            color: transparent;
        }

        &:-moz-placeholder {
            color: transparent;
        }
    }

    &::placeholder {
        opacity: 0.7;
    }

    &-plaintext {
        color: $Emperor;
        height: calc(2.17em + .65rem + 2px);
    }
}

.form-control2 {
    padding: 6px 13px;
    line-height: 18px;
    display: block;
    width: 100%;
    height: auto;
    font-size: 15px;
    font-weight: 400;
    color: #878787;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.form-right-icon {
    position: relative;

    >i {
        position: absolute;
        right: 15px;
        top: 50%;
        display: block;
        pointer-events: none;
        transform: translateY(-50%);
    }
}

/*** card ****/
.card {
    border: 0;
    border-radius: 8px;
    letter-spacing: .5px;
    margin-bottom: 30px;
    transition: all .3s ease;
    box-shadow:1px 5px 10px 0 rgba(17, 17, 19, 0.22);

    &.patient-card {
        height: calc(100vh - 170px);
    }

    &.patientannouncement-card {
        height: calc(100vh - 145px);
    }

    &.announcement-card {
        height: calc(100vh - 115px);
    }

    &.order-card {
        height: calc(100vh - 215px);
        overflow-y: scroll;
    }

    &.card-hover:hover {
        box-shadow: 0 5px 10px 0 rgba($black, 0.3);
    }

    .card-header {
        border-bottom: 1px solid #dcdcdc;
        background-color: transparent;
        padding: 15px;

        &.primary {
            background-color: #f2f2f2;
            border-bottom: 1px solid #5c5c5c;

            .card-title {
                font-weight: 600;
            }
        }
    }

    .card-title {
        margin-bottom: 0;
        line-height: inherit;
    }

    .card-body {
        padding: 23px;
    }

    .card-footer {
        background-color: transparent;
        border-top: 1px solid $AthensGray;
    }

    &-hour-zoom {
        transition: all 0.6s ease-in-out;

        &:hover {
            transform: scale(1.03);
            box-shadow: 0 5px 10px 0 rgba($black, 0.4);
        }
    }
}

.Line2textlimit {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Line1textlimit {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/***** badge ********/
.badge {
    padding: 0.6em 0.9em;
    font-size: 97%;
    font-weight: 600;
    border-radius: 3rem;

    &-primary {
        background-color: $primary
    }

    &-secondary {
        background-color: $secondary
    }

    &-success {
        background-color: $success
    }

    &-info {
        background-color: $info
    }

    &-warning {
        background-color: $warning
    }

    &-danger {
        background-color: $danger
    }

    &-light {
        background-color: $light
    }

    &-dark {
        background-color: $dark
    }
}

/***** Dropdown ********/
.dropdown {
    .dropdown-menu {
        border: 0 solid transparent;
        border-radius: .6rem;
        box-shadow: 0 12px 23px 0 rgba($black, 0.13);

        .dropdown-item {
            padding-top: 8px;
            padding-bottom: 8px;

            &:active {
                background-color: $primary;
            }
        }
    }
}

.modal-content {
    border-radius: 1.25rem;
}