@use "variable" as *;
.sidebar {
    @media (max-width : 1025px) {
        &.collapsed-mobliemenu .sidebar-desktop {
            display: none;
        }

        &.collapsed-mobliemenu .sidebar-mobile {
            display: block;
        }
    }

    .sidebar-desktop {
        position: relative;
        height: 100vh;

        .logo-light {
            display: none;
        }

        .logo {
            display: block;
        }

        .logoicon {
            display: none;
        }

        .text-view {
            display: inline-block;
        }

        .profile-menu {
            margin-top: 0.5rem;
        }

        .logo-phoneno {
            display: block;
        }

        .collapsed-menu {
            .profile-name {
                display: none;
            }

            .profile-menu {
                margin-top: 0;
            }

            .menu-title {
                display: none;
            }

            .nav-item {
                width: 46px;
            }

            .logo {
                display: none;
            }

            .logoicon {
                display: block;
            }

            .text-view {
                display: none;
            }

            .logo-phoneno {
                display: none;
            }

            .dropdown-profile {
                .profile {
                    padding: .1rem 0.4rem;
                }
            }
        }

        .menu-wrap {
            height: calc(100% - 216px);
            overflow-y: auto;

            @media (min-width : 768px) and (max-width : 1366px) {
                height: calc(100% - 220px);
            }

            .menu {
                flex-direction: column;

                .link {
                    display: block;
                    text-decoration: none;
                    color: $black;
                    padding: 13px 15px;

                    .arrowdown {
                        display: none;
                    }

                    i {
                        margin-right: 10px;
                    }

                    &.active {
                        .arrowdown {
                            display: block;
                        }

                        .arrowright {
                            display: none;
                        }

                        +.submenu {
                            height: auto;
                            transform: scaleY(1);
                        }
                    }
                }

                .submenu {
                    height: 0;
                    padding-left: 39px;
                    flex-direction: column;
                    overflow: hidden;
                    transition: transform 0.15s ease-out;
                    transform: scaleY(0);
                    transform-origin: top;

                    .link {
                        font-size: 13px;
                        padding: 9px 1px;
                        color: rgba($black, 0.8);

                        .subarrowdown {
                            display: none;
                        }

                        &:hover {
                            color: $primary;
                            transition: .5s;
                        }

                        &.active {
                            .subarrowdown {
                                display: block;
                            }

                            .subarrowright {
                                display: none;
                            }

                            +.childrenmenu {
                                height: auto;
                                transform: scaleY(1);
                            }
                        }
                    }

                    .childrenmenu {
                        height: 0;
                        padding-left: 18px;
                        flex-direction: column;
                        overflow: hidden;
                        transition: transform 0.15s ease-out;
                        transform: scaleY(0);
                        transform-origin: top;

                        .link {
                            font-size: 13px;
                            padding: 9px 1px;
                            color: rgba($white, 0.8);

                            &:hover {
                                color: $primary;
                                transition: .5s;
                            }
                        }
                    }
                }

                >.nav-item {
                    >.link {
                        &:hover {
                            background-color: $primary;
                            border-radius: 8px;
                            color: $white;
                        }

                        &.active {
                            background-color: $primary;
                            border-radius: 8px;
                            color: $white;
                        }
                    }
                }
            }
        }

        .dropdown-profile {
            .dropdown-toggle::after {
                content: none;
            }

            .profile {
                background-color: #F3F4F6;
                border: 0;
                border-radius: 8px;
            }

            .profile:hover {
                background-color: #F3F4F6;
                border: 0;
                border-radius: 8px;
            }

            .nav-j i {
                color: $black;
                font-size: 18px;
            }
        }

        .ant-layout-sider {
            min-height: 100vh;
            height: 100%;
            background: $white;
            box-shadow: 1px 5px 10px 0 rgba(17, 17, 19, 0.22);
            z-index: 10;
        }

        .custom-sidebar {
            position: fixed;
            right: 0;
            z-index: 5;

            h5 {
                color: $black;
            }

            label {
                color: $black;
                font-size: 14px;
            }

            .ant-layout-sider {
                padding-top: 95px;
                height: 100%;
                background: $compounding;
            }

            .colortrigger {
                padding-top: 155px;
                position: fixed;
                transition: all .6s;
                right: 0;
            }

            .colortrigger.open {
                transition: all .5s;
                right: 200px;
            }
        }

        .navbar-brand-icon {
            display: none;
        }
    }

    .sidebar-mobile {
        position: absolute;
        padding-top: 15px;
        padding-left: 15px;
    }

    .menutrigger {
        text-decoration: none;
        line-height: 1.5715;
        position: relative;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        background-image: none;
        height: 32px;
        padding: 4px 15px;
        font-size: 14px;
        border-radius: 2px;
        color: rgba(0, 0, 0, .85);
        border: none;
        background: transparent;
        box-shadow: none;
        user-select: none;
        cursor: pointer;
    }
}

.drawer-notification {
    .n-datetime {
        color: gray;
    }
}