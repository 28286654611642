@use "variable" as *;
//========Common 
label {
    color: #545454;
}

.table-qaqcchecklist {
    border: 1px solid #7a7a7a;
}

.theme-breadcrumb {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $black;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    font-size: 14px;

    ol {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;

        a {
            color: $black;
            text-decoration: none;
            font-weight: 500;
        }

        .breadcrumb-separator {
            margin: 0 8px;
            color: rgba(0, 0, 0, .45);
        }
    }
}

.theme-divider {
    box-sizing: border-box;
    padding: 0;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
}

.theme-divider-horizontal {
    clear: both;
    width: 100%;
    min-width: 100%;

    &.theme-divider-with-text {
        display: flex;
        margin: 10px 0;
        color: rgba(0, 0, 0, .85);
        font-weight: 500;
        font-size: 16px;
        white-space: nowrap;
        text-align: center;
        border-top: 0;
        border-top-color: rgba(0, 0, 0, .06);

        &:before {
            position: relative;
            top: 50%;
            width: 50%;
            border-top: 1px solid $black;
            border-bottom: 0;
            transform: translateY(50%);
            content: "";
        }

        &:after {
            position: relative;
            top: 50%;
            width: 50%;
            border-top: 1px solid $black;
            border-bottom: 0;
            transform: translateY(50%);
            content: "";
        }
    }
}

//======== Pdf-Toolbar
.pdf-toolbar {
    text-align: center;
    background-color: #eeeeee;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    padding: 4px;

    ul {
        list-style-type: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
            display: inline-block;
        }
    }

    .pdf-navigation {
        .current-input {
            width: 50px;
            padding-right: 10px;
        }
    }
}

.pdf-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.button-space {
    gap: 8px;
    display: inline-flex;
}

.card-header.blue_heading {
    font-size: 16px;
    font-weight: 500;
    background: $primary;
    padding: 10px 15px;
    color: $white;
    margin: 0;
}

.orderstatus-list {
    list-style-type: none;

    li {
        float: left;
        padding: 10px;

        a {
            color: white;
            text-align: center;
            padding: 16px;
            text-decoration: none;
        }
    }
}

//======== Report 
.Orderbystatus-report {
    .ant-descriptions-item-label {
        padding: 6px 16px !important;
        width: 20%;
    }

    .ant-descriptions-item-content {
        padding: 6px 16px !important;
        background-color: #FAFAFA;
    }
}

//======== Dashborad
.order-status {
    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px lightgray;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $primary;
    }

    .announcement-title {
        white-space: nowrap;
        width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 500;
        color: $black;
    }

    .announcement-desc {
        padding-top: 10px;
        display: block;
        display: -webkit-box;
        margin: 0 auto;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .statuscard {
        border: 0px;
    }

    .dashboardchart {
        height: 200px;
    }

    .storedetail {
        text-align: center;
        border: 1px solid $secondary;
        border-radius: 50%;
        width: 10.492rem;
        height: 10.492rem;
        padding: 2.5rem 0;
    }

    .order-statuscards {
        .status-iconbox {
            position: absolute;
            right: 50px;
            top: 35px;
            color: $primary;
            height: 53px;

            .status-icon {
                position: absolute;
                font-size: 30px;
            }
        }
    }
}

//======== Announcements 
.Announcements {
    .ant-table-thead>tr>th {
        font-size: 16px;
        font-weight: 500;
        background: $primary;
        padding: 10px 15px;
        color: $white;
        text-align: center;
    }

    tbody.ant-table-tbody {
        border-bottom: 1px solid;
    }
}


//======== Home Page Card 
.bg-gradient-skyblue {
    background: linear-gradient(45deg, #17ead9, #6078ea) !important;
}

.bg-gradient-red {
    background: linear-gradient(45deg, #f54ea2, #ff7676) !important;
}

.bg-gradient-green {
    background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}

.bg-gradient-yellow {
    background: linear-gradient(45deg, #ffdf40, #ff8359) !important;
}

.widgets-icons-2 {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 27px;
}

.ms-card {
    margin-bottom: 2rem;
    border-radius: 5px;
}

.ms-card.card-gradient-custom {
    background-color: #fff;
    background-image: linear-gradient(90deg, rgba(0, 158, 251, .8), #fff);
}

.ms-card.card-gradient-custom-warning {
    background-color: #fff;
    background-image: linear-gradient(90deg, #ffd500, #fff);
}

.ms-card.card-gradient-custom-danger {
    background-color: #fff;
    background-image: linear-gradient(90deg, #ff7676, #fff);
}

.ms-card.card-gradient-custom-success {
    background-color: #fff;
    background-image: linear-gradient(90deg, #96c93d, #fff);
}

.ms-p-relative {
    position: relative;
    overflow: hidden;
}

.ms-card-body,
.ms-card-footer {
    position: relative;
    padding: 1rem;
    font-size: 14px;
}

.media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0s;
}

.ms-infographics-widget .ms-card-body h6 {
    text-transform: uppercase;
}

.media-body {
    flex: 1 1;
}

.ms-infographics-widget.ms-p-relative .ms-card-body+i {
    font-size: 32px;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: #009efb;
    padding: 12px;
    border-radius: 50px 0 0 50px;
    text-align: center;
    line-height: 2;
    width: 30%;
    height: 100%;
}

.icon-warning.ms-infographics-widget.ms-p-relative .ms-card-body+i {
    font-size: 32px;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: #ffd500;
    padding: 12px;
    border-radius: 50px 0 0 50px;
    text-align: center;
    line-height: 2;
    width: 30%;
    height: 100%;
}

.icon-danger.ms-infographics-widget.ms-p-relative .ms-card-body+i {
    font-size: 32px;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: #ff7676;
    padding: 12px;
    border-radius: 50px 0 0 50px;
    text-align: center;
    line-height: 2;
    width: 30%;
    height: 100%;
}

.icon-success.ms-infographics-widget.ms-p-relative .ms-card-body+i {
    font-size: 32px;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: #96c93d;
    padding: 12px;
    border-radius: 50px 0 0 50px;
    text-align: center;
    line-height: 2;
    width: 30%;
    height: 100%;
}

.ant-segmented-item-selected {
    background-color: $primary;
    color: $white;

    :hover {
        color: $white;
    }
}

.ant-modal-header {
    padding: 14px 24px 14px;
}

.modal-order-body {
    height: 750px;
}

.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav,
.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav {
    margin: 0;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
    background: $secondary;
}