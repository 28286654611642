@use "variable" as *;
.btn-secondary3-large {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 66px;
    font-size: 22px;
    line-height: 1;
    color: #FFFFFF;
    font-weight: 700;
    border: 0;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .1);
    border-radius: 10px;
    background: #2dac6d;

    &.active,
    &:hover {
        color: $white;
        background-color: $secondary;
        border-color: $secondary;
    }
}

.form-label3 {
    padding: 0 0 15px;
    margin: 0;
    display: block;
    color: #222;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
}

.form-control3 {
    padding: 0 20px;
    height: 57px;
    display: block;
    width: 100%;
    color: #555;
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    border: 1px solid #dadada;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background: #f6f6f6;
    &.ant-select-single:not(.ant-select-customize-input) {
        padding: 0;

        .ant-select-selector {
            height: inherit;
            background-color: transparent;
            border: 1px solid transparent;

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
                line-height: 50px;
            }

            // .ant-select-selection-search-input:focus {
            //     background-color: #FFF;
            // }
        }

        &.ant-select-focused {
            background: #FFF;
        }
    }

    &.ant-picker {
        padding: 0;

        .ant-picker-input {
            height: inherit;
            padding: 0 20px;

            >input {
                font-size: inherit;
                line-height: inherit;
            }
        }

        &.ant-picker-focused {
            background: #FFF;
        }
    }
}

.ant-checkbox3 {
    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #2dac6d;
        border-color: #2dac6d;
    }
}

.ant-input-3 {
    .ant-input-group {
        .ant-input-affix-wrapper {
            width: 100%;
            height: 57px;
            padding: 0 20px;
            color: #555;
            font-size: 18px;
            line-height: 1;
            font-weight: 400;
            border: 1px solid #dadada;
            border-radius: 10px;
            background: #f6f6f6;

            >input.ant-input {
                float: none;
                font-size: inherit;
                line-height: inherit;
                background-color: transparent;
            }
        }

        .ant-input-search-button {
            height: 57px;
            font-size: 31px;
            background: #2dac6d;
            border-color: #2dac6d;
        }
    }
}

.ant-table-3 {
    .ant-table {
        font-size: 16px;
    }

    .ant-table-thead {
        >tr>th {
            background: #2dac6d;
            color: white;
            padding: 16px;
            padding-left: 20px;

            &.ant-table-column-has-sorters:hover {
                background: rgb(23, 162, 93);
            }
        }
    }

    .ant-table-tbody {
        >tr>td {
            padding: 16px;
            padding-left: 20px;
        }
    }
}

.common-main-card {
    max-width: 610px;
    margin-inline: auto;
    border: 1px solid #b5d0d9;

    .card-header {
        padding: 30px 39px;
    }

    .card-body {
        padding: 49px 39px 35px;
    }
}

.common-page-wrap {
    position: " relative";
    padding: 73px 20px;
    min-height: 100vh;
    background: #ebf0f6;
}