/*** color ***/
$primary: #00b1c7;
$secondary: #27ac77;
$success: #2bc155;
$danger: #f72336;
$warning: #ffbc11;
$lightwarning: #fcf2c5;
$darkwarning: #d6ae00;
$info: #1ea7c5;
$light: #f4f5f9;
$dark: #b1b1b1;
$darktheme: #161616;
$darktheme2: #1f1f1f;
$layoutbg: #F9FAFB;
$primaryhover: #0a8d9d;
$secondaryhover: #11a469;
$store: #E9FBFF;
$user: #EEF2FF;
$successhover: #24a247;
$dangerhover: #d21425;
$warninghover: #eaa800;
$infohover: #198ba4;
$lighthover: #fff;
$darkhover: #989898;
//=========System Page
$product: #F5F3FF;
$announcement: #e3faff;
$users: #ECFDF5;
$template: #FFFBEB;
$qaqc: #EFF6FF;
$other: #FEF2F2;
//=========
$white: white;
$black: black;
$blue: #0378d5;
$lightblack: #323232;
$MineShaft: #333333;
$Emperor: #555555;
$Tundora: #404040;
$PickledBluewood: #2f3c4e;
$gray: #888888;
$lightgray: #d3d3d3;
$red: #F45846;
$green: #2cc185;
$yellow: #ffff00;
$orange: #f58220;
$WildSand: #F4F4F4;
$Alabaster: #F7F7F7;
$Seashell: #F1F1F1;
$LinkWater: #EBF2FA;
$AthensGray: #F0F1F5;
$Alto: #CECECE;
//====== socail brand color 
$Facebook: #1877f2;
$Twitter: #1da1f2;
$LinkedIn: #0a66c2;
$Skype: #00aff0;
$YouTube: #ff0000;
$Google: #4285f4;
$Instagram: #c32aa3;
$Pinterest: #bd081c;
$Snapchat: #fffc00;
$WhatsApp: #25d366;
$Android: #a4c639;
$footer: #F3F4F6;
//====== Dark Color
$darkfinalcheck : #187d44;
$darkpending : #d97706;
$darkcancelled : #e42a16;
$darkarchived : #e42a16;
$darkfollowup : #2262d3;
$darkconfirmed : #187d44;
$darkfastmoving : #a808c3;
$darkcompounding : #797D7F;
$darkreadyforcollection : #2E4053;
$darkposted : #e9740e;
$darkrejected : #A93226;
//====== Order Status 
$pending : #fffbeb;
$followup : #e8f5ff;
$confirmed : #d2ffe4;
$fastmoving : #fceeff;
$compounding : #f1f1f1;
$finalcheck : #d2ffe4;
$readyforcollection : #f4f4f4;
$posted : #fff4ea;
$cancelled : #ffefeb;
$archived : #ffefeb;
$rejected : #fff0ee;
/*******************/
$radios1: 12px;
$radios2: 20px;
$radios3: 25px;
$radios4: 30px;
$radios4: 40px;
$radios5: 50px;
/*-------------------*/